import { useState, useEffect } from "react";
import axios from "axios";
import {  Alert, Row, Col } from "react-bootstrap";
import Config from "../../config";
import ReactLoading from 'react-loading';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
const DocDownload = () => {

    const params = useParams()
    const histroy = useHistory()
    const [showAlert, setAlertShow] = useState(false);
    const [message, setMessage] = useState()
    const [alertType, setAlertType] = useState("danger")
    const [buttonStatus, setButtonStatus] = useState(false)
    const data = JSON.parse(localStorage.getItem('jwtToken'));


    const getFile =async (e) => {
        setButtonStatus(true);
        let filename="file.pdf"
        let token = "";
        if (data?.token) {
            token = data.token
        }
        const file_data=await axios.get(Config.BASE_URL + "/api/user/docs_url/file_data/" + params.file_id,{
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        if(file_data.data.success){
            filename=file_data.data.fileData.title+"."+file_data.data.fileData.file_type
        }

        if(file_data.data.is_subscribed && file_data.data.is_subscribed=="canceled"){
            histroy.push("/subscription/canceled")
        }else{
            axios({
                url: Config.BASE_URL + "/api/user/docs_url/download/" + params.file_id,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    "authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    if (res.data.type === "application/json") {
                        if(res.data.is_subscribed && res.data.is_subscribed=="canceled"){
                            histroy.push("/subscription/canceled")
                        }else{
                            histroy.push("/login")
                        }
                    } else {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
    
                        setButtonStatus(false);
                        setAlertType("success")
                        setAlertShow(true)
                        setMessage("File has been downloaded")
                    }
                })
                .catch(e => {
                    setAlertType("success")
                    setAlertShow(true)
                    setMessage("File has been downloaded")
                    setButtonStatus(false);
                });    

        }
  

    };
    useEffect(() => {
        getFile()
    }, [])
    return (
        <>
            {buttonStatus && <ReactLoading type={"spokes"} color={"#1c56ac"} className="loader" />}
            <Row>
                <Col>
                    <Alert variant={alertType} show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
                </Col>

            </Row>
        </>
    );
};

export default DocDownload;
