import {ActionTypes} from '../types';

export const addSessionData=(data)=>async(dispatch,getStore)=>{
    dispatch(storeSession(data)) 
}

export const addSessionData2=()=>async(dispatch,getStore)=>{
    const data=JSON.parse(localStorage.getItem('jwtToken'));
    if(data){
        dispatch(storeSession(data)) 
    } 
}

export const removeSession=()=>async(dispatch,getStore)=>{
    dispatch(removeSessionState())
    localStorage.removeItem('jwtToken')
}


const removeSessionState=()=>{
    return {
        type:ActionTypes.REMOVE_SESSION,
        payload:{}
    }
}

const storeSession=(data)=>{
    return {
        type:ActionTypes.STORE_SESSION,
        payload:data
    }
}

