export const ActionTypes={
    LOADER:'LOADER',
    STORE_SESSION:'STORE_SESSION',
    SET_USER_DATA:'SET_USER_DATA',
    REMOVE_SESSION:'REMOVE_SESSION',
    ADD_NEW_NOTIFICATION:'ADD_NEW_NOTIFICATION',
    SET_THEME_STATE:'SET_THEME_STATE',
    SET_UPDATE_DATA:'SET_UPDATE_DATA',
    SET_COMPANY_LIST:'SET_COMPANY_LIST',
    SET_USER_BOOK_LIST:'SET_USER_BOOK_LIST',
    SET_ALL_FILTER_DATA:'SET_ALL_FILTER_DATA',
    SET_OGD_DATA:'SET_OGD_DATA',
    SET_FTM_DATA:'SET_FTM_DATA',
    SET_DATE_DATA:'SET_DATE_DATA',
    SET_USA_COMPANY_LIST:'SET_USA_COMPANY_LIST',
    SET_WEBSITE_MODE:'SET_WEBSITE_MODE',
    SET_SUB_STATUS_DATA:"SET_SUB_STATUS_DATA",
    UPDATE_DATA_FLAG:"UPDATE_DATA_FLAG",
    SET_GALLERY_MODE:'SET_GALLERY_MODE'
}