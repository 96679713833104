import {ActionTypes} from '../types';
import axios from 'axios';
import Config from '../../config';

export const addBookList=()=>async(dispatch,getStore)=>{
    const user=getStore().sessionStore
    if(user.token){
        axios.get(Config.BASE_URL + "/api/user/get_portfolio_list",{
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            let option=[]
            let option2=[]
            let final_data={
        
            }
            if(res.data.my.length>0){
           
                res.data.my.forEach((obj) => {
                    option.push({
                        label: obj.book_name,
                        value: obj._id,
                    });
                });
                final_data.my=option
    
            }
            if(res.data.usa.length>0){
   
                res.data.usa.forEach((obj) => {
                    option2.push({
                        label: obj.book_name,
                        value: obj._id,
                    });
                });
                final_data.usa=option2
             
            }
            dispatch({
                type:ActionTypes.SET_USER_BOOK_LIST,
                payload:final_data
            })
            
        });
    }
}
let flag=true

export const getUserFilter=()=>async(dispatch,getStore)=>{
    const user=getStore().sessionStore
    if(user.token){
        axios.get(Config.BASE_URL + "/api/user/get_filter",{
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            dispatch({
                type:ActionTypes.SET_ALL_FILTER_DATA,
                payload:res.data
            })
            if(flag){
                flag=false
                dispatch({
                    type:ActionTypes.SET_WEBSITE_MODE,
                    payload:res.data.default_country
                })
            }
           
        });
    }
}


