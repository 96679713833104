import {createStore,combineReducers,compose,applyMiddleware} from 'redux';
import sessionReducer from './reducer/sessionReducer';
import notificationReducer from './reducer/notificationReducer';
import dataReducer from './reducer/dataReducer';
import filterReducer from './reducer/filterReducer';

import thunk from 'redux-thunk'


const mainReducer=combineReducers({
    sessionStore:sessionReducer,
    notificationStore:notificationReducer,
    dataStore:dataReducer,
    filterStore:filterReducer

})

const composeEnhancers=window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store=createStore(mainReducer,composeEnhancers(applyMiddleware(thunk)));

export default store;