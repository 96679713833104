import React from 'react'
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

var jodit_2;
const config = {
  minHeight: 500,
  enableDragAndDropFileToEditor: true,
  enter: "BR",
  uploader: {
      url: 'https://jerungdetector.com/connector/upload',
      "insertImageAsBase64URI": false,
      "imagesExtensions": [
        "jpg",
        "png",
        "jpeg",
        "gif"
      ],
      filesVariableName: function(e){return "files"},
      isSuccess: function (resp) {
          return !resp.error;
      },
      "getMessage": "function(e){return void 0!==e.data.messages&&(0,s.isArray)(e.data.messages)?e.data.messages.join(\" \"):\"\"}",
      process: function (resp,events) {
          var i, images = resp["images"] || [];
          if (images.length) {
              for (i = 0;i < images.length; i += 1) {
                  images[i] = '/' + images[i]
              }
          }
          return {
              files: images,
              path: 'media',
              baseurl: 'https://jerungdetector.com/media/editor',
              error: resp.error,
              msg: resp.msg
          };
      },
      defaultHandlerSuccess: function (data) {
     
          var i, field = "files";
          console.log(this,jodit_2)
         
          if (data[field] && data[field].length) {
         
              for (i = 0; i < data[field].length; i += 1) {
                  // this.selection.insertImage(data.baseurl + data[field][i], null, 250);
                  jodit_2.s.insertImage(data.baseurl + data[field][i])
              }
          }
      },
      "defaultHandlerError": "function(e){this.j.e.fire(\"errorMessage\",e.message)}",
  },
  buttons: ["bold","italic","underline","strikethrough","ul","ol","left","font","fontsize","brush","link","symbol","table","eraser","source"] ,
  events: {
      afterInit: (instance) => {
        jodit_2 = instance;
      },
  }
      
};



const Editor = ({ value, setFormInput,name='editordata' }) => {

  return (
                             <JoditEditor 
                              value={value}
                              config={config} 
                              onBlur={newContent => { setFormInput({ [name]: newContent })}} />
  )
}

export default Editor