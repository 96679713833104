import React from 'react';
import {Redirect,Route,useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
const PrivateRoute = ({component: Component, ...rest}) => {
    const session=useSelector((state)=>state.sessionStore)
    const location=useLocation()

    return (

        <Route {...rest} render={props => (
            session.token && session.expire_at>new Date().valueOf() ? session.is_subscribed!=="not_active"? session.is_subscribed=="canceled"?<Redirect to={{
                pathname: "/subscription/canceled",
                state: { from: location.pathname }
                }}/>:
                <Component {...props} />: 
               <Redirect to={{
                pathname: "/subscription",
                state: { from: location.pathname }
                }}/>
                : <Redirect to={{
                    pathname: "/login",
                    state: { from: location.pathname }
                }}/>
        )} />
    );
};

export default PrivateRoute;