import {ActionTypes} from '../types';
import axios from 'axios';
import Config from '../../config';
import {removeSession} from '../action/sessionAction';
export const addCompanyList=()=>async(dispatch,getStore)=>{
    axios.get(Config.BASE_URL + "/api/company").then((res) => {
        let option = [];
        res.data.forEach((obj) => {
          option.push({
            label: obj,
            value: obj,
          });
        });
        dispatch(addCompnayRedux(option)) 
    });
    
}

export const addUSACompanyList=()=>async(dispatch,getStore)=>{
  axios.get(Config.BASE_URL + "/api/usa/company").then((res) => {
      let option = [];
      res.data.forEach((obj) => {
        option.push({
          label: obj,
          value: obj,
        });
      });
      dispatch(addUSaCompnayRedux(option)) 
  });
  
}

export const getUserSubStatus=()=>async(dispatch,getStore)=>{
  const data=JSON.parse(localStorage.getItem('jwtToken'));
  if(data?.token){
    axios.get(Config.BASE_URL + "/api/usa/sub_status",{
      headers: {
          "authorization": `Bearer ${data.token}`
      }
    }).then((res) => {
        data.is_subscribed=res.data.is_subscribed
        localStorage.setItem('jwtToken',JSON.stringify(data));
        dispatch({
          type:ActionTypes.SET_SUB_STATUS_DATA,
          payload:res.data.is_subscribed
      }) 
    });
  }
  
}


export const updateDataFlag=()=>async(dispatch,getStore)=>{
  const old_Flag=getStore().dataStore.data_flag
  dispatch({

    type:ActionTypes.UPDATE_DATA_FLAG,
    payload:!old_Flag
  }) 
  
}



export const getDailyData=()=>async(dispatch,getStore)=>{
  const token=getStore().sessionStore?.token

  axios.get(Config.BASE_URL + "/api/daily_data",{
    headers: {
        "authorization": `Bearer ${token}`
    }
  }).then((res) => {

      dispatch(addDailyDataToRedux(res.data)) 
  }).catch((e)=>{
    if(e.response !=null && e.response.data !=null && e.response.data.code==401){
        dispatch(removeSession()) 
    }
  });
  
}

export const getDateData=()=>async(dispatch,getStore)=>{
  const token=getStore().sessionStore?.token
  const session=localStorage.getItem('session');

  axios.get(Config.BASE_URL + "/api/user/available_date?session="+session,{
    headers: {
        "authorization": `Bearer ${token}`
    }
  }).then((res) => {
    if(res.data.session){
      localStorage.setItem('session',res.data.session);
    }

    dispatch( {
      type:ActionTypes.SET_DATE_DATA,
      payload:res.data
    })
  }).catch((e)=>{
    if(e.response !=null && e.response.data !=null && e.response.data.code==401){
        dispatch(removeSession()) 
    }
  });;
  
}




export const getOgdData=()=>async(dispatch,getStore)=>{
  const token=getStore().sessionStore?.token
  axios.get(Config.BASE_URL + "/api/ogd_data",{
    headers: {
        "authorization": `Bearer ${token}`
    }
  }).then((res) => {
      dispatch(addOgdDataToRedux(res.data)) 
  }).catch((e)=>{
    if(e.response !=null && e.response.data !=null && e.response.data.code==401){
        dispatch(removeSession()) 
    }
  });
  
}

export const getFtmData=()=>async(dispatch,getStore)=>{
  const token=getStore().sessionStore?.token
  axios.get(Config.BASE_URL + "/api/ftm_data_filter",{
    headers: {
        "authorization": `Bearer ${token}`
    }
  }).then((res) => {
      dispatch(addFtmDataToRedux(res.data));
  }).catch((e)=>{
    if(e.response !=null && e.response.data !=null && e.response.data.code==401){
        dispatch(removeSession()) 
    }
  });;
  
}

const addDailyDataToRedux=(data)=>{
  return {
      type:ActionTypes.SET_UPDATE_DATA,
      payload:data
  }
}

const addFtmDataToRedux=(data)=>{
  return {
      type:ActionTypes.SET_FTM_DATA,
      payload:data
  }
}

const addOgdDataToRedux=(data)=>{
  return {
      type:ActionTypes.SET_OGD_DATA,
      payload:data
  }
}



const addCompnayRedux=(data)=>{
    return {
        type:ActionTypes.SET_COMPANY_LIST,
        payload:data
    }
}

const addUSaCompnayRedux=(data)=>{
  return {
      type:ActionTypes.SET_USA_COMPANY_LIST,
      payload:data
  }
}


