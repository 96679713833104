import {ActionTypes} from '../types'
const initial={
    book_list:[],
    ticker_list:[],
    filter_book:[],
    ticker_list2:[],
    filter_book2:[],
    ticker_filter_alert:[],

    book_list_usa:[],
    ticker_list_usa:[],
    ticker_list_usa2:[],
    filter_book_usa:[],
    filter_book_usa2:[],
    filter_ma:[],
    filter_ma_usa:[],

    pinned_company:[],
    is_shariah_dd:"",
    change_p_dd:"",
    dnv_dd:"",
    dnv_ratio_dd:"",
    sorting_dd:"",
    sorting_order_dd:"",

    pinned_company_usa:[],
    
    is_shariah_dd_usa:"",
    change_p_dd_usa:"",
    dnv_dd_usa:"",
    dnv_ratio_dd_usa:"",
    sorting_dd_usa:"",
    sorting_order_dd_usa:"",


    is_shariah_ogd:"",
    change_p_ogd:"",
    dnv_ogd:"",
    dnv_ratio_ogd:"",
    pakya_ratio_ogd:"",
    sorting_ogd:"",
    sorting_order_ogd:"",

    is_shariah_ogd_usa:"",
    change_p_ogd_usa:"",
    dnv_ogd_usa:"",
    dnv_ratio_ogd_usa:"",
    pakya_ratio_ogd_usa:"",
    sorting_ogd_usa:"",
    sorting_order_ogd_usa:"",

    is_shariah_15m:"",
    change_p_15m:"",
    dnv_15m:"",
    dnv_ratio_15m:"",
    cum_dnv_15m:"",
    pakya_ratio_15m:"",

    is_shariah_15m_usa:"",
    change_p_15m_usa:"",
    dnv_15m_usa:"",
    dnv_ratio_15m_usa:"",
    cum_dnv_15m_usa:"",
    pakya_ratio_15m_usa:"",

    sorting_portfolio:"",
    sorting_order_portfolio:"",

    sorting_portfolio_history:"",
    sorting_order_portfolio_history:"",

    sorting_portfolio_usa:"",
    sorting_order_portfolio_usa:"",

    sorting_portfolio_history_usa:"",
    sorting_order_portfolio_history_usa:"",
    default_country:"MY",
    default_trading_journal:"/trading_journal",

    is_shariah_alert:"",
    change_p_ogd_alert:"",
    alert_type:""

}

const filterReducer=(state=initial,action)=>{
    switch(action.type){
        case ActionTypes.SET_USER_BOOK_LIST:
            return {...state,book_list:action.payload.my,book_list_usa:action.payload.usa}
        case ActionTypes.SET_ALL_FILTER_DATA:
            return {...state,...action.payload
            }
        default:
            return state
    }

}

export default filterReducer;