const capitalizeWords = (str) => {
    if(!str){
        return ''
    }
    const words = str.split(" ");
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const capitalizedSentence = capitalizedWords.join(" ");
    return capitalizedSentence;
};

export default capitalizeWords