// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging  } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyC4xrYZBuASAL_rDl7ms835ghd0BVrgXIc",
    authDomain: "jd-website-614bd.firebaseapp.com",
    projectId: "jd-website-614bd",
    storageBucket: "jd-website-614bd.firebasestorage.app",
    messagingSenderId: "437995277685",
    appId: "1:437995277685:web:299797b2d0876ef864973c",
    measurementId: "G-FPHZMZS82J"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
